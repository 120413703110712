import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { css } from "@emotion/css";
import tw from "twin.macro";
import { Popover, Transition } from "@headlessui/react";
import { StaticImage } from "gatsby-plugin-image";
import {
  MenuIcon,
  XIcon,
  ArrowSmDownIcon as ArrowIcon,
} from "@heroicons/react/outline";
import styles from "./styles";

const menu = ["about", "lessons", "gallery", "contacts"];

const NavItem = ({ title, href }: { title: string; href: string }) => {
  return (
    <a
      href={href}
      tw="text-lg font-medium text-gray-900 hover:(border-black) border-b border-transparent transition duration-500 ease-in-out"
    >
      {title}
    </a>
  );
};

const Header = ({ t, lang }: { t: Record<string, string>; lang: string }) => {
  const [sticky, setSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setSticky(window.scrollY > 0);
    };
    document.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Popover css={styles.header({ sticky })}>
      {({ open }) => (
        <>
          <div tw="container mx-auto px-4 sm:px-6 xl:px-0">
            <div tw="flex justify-between items-center py-6 md:justify-start md:space-x-10">
              <div tw="flex justify-start lg:w-0 lg:flex-1">
                <a href="#home">
                  <span tw="sr-only">{t.title}</span>
                  <StaticImage
                    src="../../../images/logo.png"
                    alt={t.logoAlt}
                    placeholder="blurred"
                    layout="fixed"
                    height={48}
                    tw="h-8 w-auto sm:h-10"
                  />
                </a>
              </div>
              <div tw="-mr-2 -my-2 md:hidden flex items-center">
                <Link
                  title={
                    lang === "pt" ? "English version" : "Versão em Português"
                  }
                  to={lang === "pt" ? "/en" : "/"}
                  tw="whitespace-nowrap pr-4 text-2xl"
                >
                  {lang !== "en" && (
                    <StaticImage
                      src="../../../images/en.svg"
                      alt={"English version"}
                      placeholder="blurred"
                      layout="fixed"
                      width={25}
                      height={25}
                    />
                  )}
                  {lang === "en" && (
                    <StaticImage
                      src="../../../images/pt.svg"
                      alt={"Versão em Português"}
                      placeholder="blurred"
                      layout="fixed"
                      width={25}
                      height={25}
                    />
                  )}
                </Link>
                <Popover.Button tw="p-2 inline-flex items-center justify-center text-gray-400 focus:outline-none">
                  <span tw="sr-only">Open menu</span>
                  <MenuIcon tw="h-6 w-6 text-gray-900" aria-hidden="true" />
                </Popover.Button>
              </div>
              <nav tw="hidden md:flex space-x-10">
                {menu.map((menuItem, index) => (
                  <NavItem
                    key={index}
                    title={t[menuItem]}
                    href={`#${menuItem}`}
                  />
                ))}
              </nav>
              <div tw="hidden md:flex items-center justify-end md:flex-1 lg:w-0">
                <Link
                  title={
                    lang === "pt" ? "English version" : "Versão em Português"
                  }
                  to={lang === "pt" ? "/en" : "/"}
                  tw="whitespace-nowrap inline-flex items-center justify-center px-4 py-2 text-2xl"
                >
                  {lang !== "en" && (
                    <StaticImage
                      src="../../../images/en.svg"
                      alt={"English version"}
                      placeholder="blurred"
                      layout="fixed"
                      width={25}
                      height={25}
                    />
                  )}
                  {lang === "en" && (
                    <StaticImage
                      src="../../../images/pt.svg"
                      alt={"Versão em Português"}
                      placeholder="blurred"
                      layout="fixed"
                      width={25}
                      height={25}
                    />
                  )}
                </Link>
                <a
                  href="#contacts"
                  tw="rounded ml-4 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 shadow text-white bg-primary transition duration-500 ease-in-out"
                >
                  <span>{t.cta}</span>
                  <ArrowIcon tw="ml-1 h-5 w-5 text-white animate-bounce" />
                </a>
              </div>
            </div>
          </div>
          <Transition
            show={open}
            enter={css(tw`transition ease-in duration-100`)}
            enterFrom={css(tw`transform opacity-0`)}
            enterTo={css(tw`transform opacity-60`)}
            leave={css(tw`transition ease-out duration-100`)}
            leaveFrom={css(tw`transform opacity-60`)}
            leaveTo={css(tw`transform opacity-0`)}
            tw="opacity-60 fixed inset-0 bg-black"
          >
            <Popover.Overlay />
          </Transition>
          <Transition
            show={open}
            enter={css(tw`transition-all ease-in-out duration-500`)}
            enterFrom={css(tw`transform top-[-312px]`)}
            enterTo={css(tw`transform top-0`)}
            leave={css(tw`transition-all ease-in-out duration-500`)}
            leaveFrom={css(tw`transform top-0`)}
            leaveTo={css(tw`transform top-[-312px]`)}
            tw="absolute top-0 inset-x-0 bg-white"
          >
            <Popover.Panel focus static>
              <div tw="flex justify-between px-4 py-6 bg-white">
                <Popover.Button
                  as="a"
                  href="#home"
                  tw="flex justify-start lg:w-0 lg:flex-1"
                >
                  <span tw="sr-only">{t.title}</span>
                  <StaticImage
                    src="../../../images/logo.png"
                    alt={t.logoAlt}
                    placeholder="blurred"
                    layout="fixed"
                    height={48}
                    tw="h-8 w-auto sm:h-10"
                  />
                </Popover.Button>
                <Popover.Button tw="bg-white right-0 focus:outline-none">
                  <span tw="sr-only">{t.closeMenu}</span>
                  <XIcon tw="h-6 w-6" aria-hidden="true" />
                </Popover.Button>
              </div>
              <div tw="py-6 px-5 space-y-6">
                <div tw="grid grid-cols-2 gap-10">
                  {menu.map((menuItem, index) => (
                    <Popover.Button
                      key={index}
                      as="a"
                      href={`#${menuItem}`}
                      tw="text-base font-medium"
                    >
                      {t[menuItem]}
                    </Popover.Button>
                  ))}
                  <Link to={lang === "pt" ? "/en" : "/"}>
                    {t.changeLangButton}
                  </Link>
                  <Popover.Button
                    as="a"
                    href="#contacts"
                    tw="rounded whitespace-nowrap inline-flex w-min items-center justify-center px-4 py-2 shadow text-white bg-primary transition duration-500 ease-in-out"
                  >
                    <span>{t.cta}</span>
                    <ArrowIcon tw="ml-1 h-5 w-5 text-white animate-bounce" />
                  </Popover.Button>
                </div>
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  );
};

export default Header;
